/*!
 * Start Bootstrap - Grayscale Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

:root {
  --font-main: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  --font-header: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  --font-awesome: "Font Awesome 5 Free";
  --color-primary: #3399ff;
  --color-primary-rgb: 32,102,255;
  --color-secondary: #788191;
  --color-secondary-rgb: 0,102,255;
  --color-dark: #1C1B1F;
  --color-dark-rgb: 28,27,31;
  --color-light: #F4F4F4;
  --color-light-rgb: 244,244,244;
  --color-gray: #343337;
  --color-gray-rgb: 52,51,55;
  --color-no-active: #cfcecd;
  --color-no-active-rgb: 207,206,205;
  --color-error: #9f0108;
  --color-error-rgb: 159,1,8;
  --color-header-primary: #ffffff;
  --color-header-primary-rgb: 61,59,66;
  --color-header-secondary: #706E72;
  --color-header-secondary-rgb: 112,110,114;
  --color-text: #ffffff;
  --color-text-rgb: 136,136,136;
  --color-text-gray: #8f949e;
  --color-text-gray-rgb: 211,211,213;
  --color-table-border: #e3dfdf;
  --color-table-border-rgb: 227,223,223;
  --color-table-light-gray: #F4F4F4;
  --color-table-light-gray-rgb: 244,244,244;
  --color-icon-bg: #D5CFE3;
  --color-icon-bg-rgb: 213,207,227;
  --color-slick-dots-bg: #C4C4C4;
  --color-slick-dots-bg-rgb: 196,196,196;
  --color-slick-arrow: #3D3B42;
  --color-slick-arrow-rgb: 61,59,66;
}

body {
    width: 100%;
    height: 100%;
    font-family: var(--font-main);
    font-weight: 300;
    color: #fff;
    background-color: #000;
}

html {
    width: 100%;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 35px;
    text-transform: uppercase;
    font-family: var(--font-header);
    font-weight: 700;
    letter-spacing: 1px;
}

p {
    margin: 0 0 25px;
    font-size: 16px;
    line-height: 1.5;
}

@media(min-width:768px) {
    p {
        margin: 0 0 35px;
        font-size: 18px;
        line-height: 1.6;
    }
}

a {
    color: var(--color-primary);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #4eb1d1;
}

.light {
    font-weight: 400;
}

.navbar-custom {
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: var(--font-header);
    -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out,padding .5s ease-in-out;
    transition: background .5s ease-in-out,padding .5s ease-in-out;
}

.navbar-custom.expanded,
.navbar-custom.top-nav-collapse {
    border-bottom: 1px solid rgba(255,255,255,.3);
    background: #000;
}

.navbar-custom.top-nav-collapse {
    padding: 0;
}

.navbar-custom .navbar-brand {
    font-weight: 700;
}

.navbar-custom .navbar-brand:focus {
    outline: 0;
}

.navbar-custom .navbar-toggler {
    padding: 11px 12px;
    font-size: 16px;
    color: #fff;
    border: none!important;
}

.navbar-custom .navbar-toggler:focus,
.navbar-custom .navbar-toggler:active {
    outline: 0;
}

.navbar-custom a {
    color: rgba(255,255,255,.4);
}
.navbar-custom .active a,
.navbar-custom a.active {
    color: rgba(255,255,255,1);
}

.navbar-custom .navbar-nav li a.nav-link {
    padding: 15px 1rem;
}

.navbar-custom .navbar-nav li a {
    -webkit-transition: color background .3s ease-in-out;
    -moz-transition: color background .3s ease-in-out;
    transition: color background .3s ease-in-out;
}

.navbar-custom .navbar-nav li a:hover {
    outline: 0;
    color: rgba(255,255,255,.8);
    background-color: transparent;
}

.navbar-custom .navbar-nav li a:focus,
.navbar-custom .navbar-nav li a:active {
    outline: 0;
    background-color: transparent;
}

.navbar-custom .navbar-nav li.active {
    outline: 0;
}

.navbar-custom.top-nav-collapse .navbar-nav li.active a,
.navbar-custom.top-nav-collapse .navbar-nav li a.active {
    background-color: rgba(255,255,255,.125);
}
/*
.navbar-custom .navbar-nav li.active a:hover {
    color: var(--color-primary);
}
*/

@media(min-width:768px) {
    .navbar-custom {
        padding: 20px 0;
        border-bottom: 0;
        letter-spacing: 1px;
        background: 0 0;
    }

    .navbar-custom.top-nav-collapse {
        padding: 0;
    }
}

.btn-circle {
    width: 70px;
    height: 70px;
    line-height: 55px;
    margin-top: 15px;
    padding: 7px 16px;
    border: 2px solid #fff;
    border-radius: 100%!important;
    font-size: 40px;
    color: #fff;
    background: 0 0;
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.btn-circle:hover,
.btn-circle:focus {
    outline: 0;
    color: #fff;
    background: rgba(255,255,255,.1);
}

.btn-circle i.animated {
    transition-property: transform;
    transition-duration: 1s;
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
}

.btn-circle:hover i.animated {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    -moz-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-moz-keyframes pulse {
    0% {
        -moz-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -moz-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -moz-transform: scale(1);
        transform: scale(1);
    }
}

section {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
nav + section {
    padding-top: 80px;
}
/*
.media-section {
    width: 100%;
    color: #fff;
    background: url(../img/media-bg.jpg) no-repeat center center scroll;
    background-color: #000;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
*/

.panel-transparent {
    background: none;
}

.panel-transparent .panel-body{
    background: rgba(46, 51, 56, 0.5)!important;
}

/*
.quote {
    color: #ccc;
    margin: 30px 50px;
    font-size: 16px;
}
*/

@media(min-width:767px) {
    section {
        padding-top: 60px;
    }
    nav + section {
        padding-top: 120px;
    }
}

.btn {
    border-radius: 0;
    text-transform: uppercase;
    font-family: var(--font-header);
    font-weight: 400;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.btn-default {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: transparent;
}

.btn-default:hover,
.btn-default:focus {
    border: 1px solid var(--color-primary);
    outline: 0;
    color: #000;
    background-color: var(--color-primary);
}

ul.banner-social-buttons {
    margin-top: 0;
}

li.social-buttons {
  margin-bottom: 10px;
}

@media(max-width:1199px) {
    ul.banner-social-buttons {
        margin-top: 15px;
    }
}

@media(max-width:767px) {
    ul.banner-social-buttons li {
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }

    ul.banner-social-buttons li:last-child {
        margin-bottom: 0;
    }
}

footer {
    background-color: #101113;
    padding: 50px 0;
    margin-top: -100px;
}

footer p {
    margin: 0;
}

::-moz-selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(255,255,255,.2);
}

::selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(255,255,255,.2);
}

img::selection {
    background: 0 0;
}

img::-moz-selection {
    background: 0 0;
}

body {
    -webkit-tap-highlight-color: rgba(255,255,255,.2);
}
