@media (max-width: 576px) {
  nav > .container {
    width: 100%;
  }
}

.navbar-brand img {
  width: 140px;
}

@media (min-width: 768px) {
  .ytv-canvas {
    height: 640px;
  }
}
@media (min-width: 992px) {
  .ytv-canvas {
    height: 437px;
  }
}
.ytv-list .ytv-content {
  padding-left: 130px;
}

footer {
  padding-bottom: 16px;
}

.content-section { background-color: black; }

.about-section .item-inner {
  padding: 20px 0px;
}
.about-section .member-name {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0px;
  text-transform: none;
  font-family: var(--font-header);
  min-height: 40px;
}
.about-section .member-desc {
  color: var(--color-text-gray);
}
.about-section .member-profile {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  text-align: center;
}
.about-section .member-profile img {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  max-width: 200px;
  width: 100%;
}
.about-section .member-label {
  display: inline-block;
  width: 80%;
  padding: 5px 10px;
  background: #202224;
  color: #fff;
  font-family: var(--font-header);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 30px;
}

.media-section .container {
  padding-bottom: 20px;
}

.media-section .album {
  padding-bottom: 20px;
}

.gigs-section .section-title {
  padding-top: 20px;
}
.events-container .item {
  position: relative;
  padding-left: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #202224;
}
.events-container .date-label {
  background: #202224;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 45px;
  height: 64px;
  padding-top: 4px;
  position: absolute;
  left: 0;
  top: 15px;
}
.events-container .date-label .number {
  font-size: 16px;
  font-weight: 700;
}
.events-container .date-label .month {
  font-size: 11px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
  font-family: var(--font-header);
  text-transform: uppercase;
}
.events-container .date-label .year {
  font-size: 11px;
  font-weight: 300;
  font-family: var(--font-header);
  color: rgba(255, 255, 255, 0.7);
}
.events-container .event-title {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700;
  width: calc(100% - 180px);
}
.events-container .meta-list {
  color: var(--color-secondary);
}
.events-container .meta-list a {
  color: var(--color-secondary);
}
.events-container .meta-list .fa,
.events-container .meta-list .fab {
  margin-right: 5px;
  color: var(--color-primary);
  font-size: 16px;
}
.events-container .event-actions {
  position: absolute;
  right: 0;
  top: 15px;
}
.events-container .event-actions .btn {
  margin-right: 5px;
}
.events-container .event-actions .btn:last-child {
  margin-right: 0;
}
.events-container .info-extra {
  color: var(--color-text-gray);
  font-size: 16px;
}
.events-container .gigs-contact {
  margin-top: 60px;
}
.events-container .gigs-contact .title {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 15px;
}
.events-container .gigs-contact .intro {
  font-size: 16px;
  color: var(--color-text-gray);
  max-width: 800px;
}
.events-container .gigs-contact .btn {
  margin-top: 15px;
}

.intro-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 160px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.intro-footer p {
  font-size: 1.2em;
  margin-bottom: 0;
}

.intro-footer .intro-footer-inner {
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
}

.intro-footer .carousel-indicators {
  bottom: .1rem;
  margin-bottom: 0;
}

.intro-footer .carousel-inner {
  min-height: 120px;
}

.intro-footer .media-holder {
  display: inline-block;
  position: absolute;
  width: 170px;
  height: 100px;
  left: 0;
}
.intro-footer .media-thumb {
  position: relative;
}
.intro-footer .media-thumb img.thumbnail {
  height: 100px;
  max-width: 170px;
}
.intro-footer .video-play-trigger {
  position: absolute;
  width: 36px;
  height: 36px;
  left: 50%;
  margin-left: -18px;
  text-align: center;
  z-index: 5;
  top: 50%;
  margin-top: -18px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  cursor: pointer;
}
.intro-footer .video-play-trigger:hover {
  background: rgba(91, 182, 111, 0.6);
}
.intro-footer .video-play-trigger .fa,
.intro-footer .video-play-trigger .fab {
  color: #fff;
  font-size: 16px;
  padding-top: 10px;
  padding-left: 4px;
}
.intro-footer .glyphicon {
  margin: 0;
  margin-top: -20px;
}
.intro-footer .carousel-control {
  display: inline-block;
  width: 23px;
  height: 100px;
  -webkit-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  text-shadow: none;
}
.intro-footer .carousel-control:hover {
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}
.intro-footer .carousel-control.right {
  background-image: none;
}
.intro-footer .carousel-control.left {
  background-image: none;
}
.intro-footer .carousel .carousel-item {
  height: 100px;
}
.intro-footer .carousel-content {
  padding-left: 200px;
  max-width: 720px;
  font-size: 12px;
}
.intro-footer .carousel-content.no-media-holder {
  padding-left: 0;
  max-width: 620px;
}
.intro-footer .carousel-content.no-media-holder .title {
  margin-bottom: 15px;
}
.intro-footer .carousel-content .title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: none;
  font-family: var(--font-main);
}
.intro-footer .carousel-content .btn-cta {
  position: absolute;
  right: 15px;
  top: 30px;
  font-size: 14px;
  padding: .5rem .75rem;
}
.intro-footer .carousel-content .btn-cta img {
  height: 14px;
}
.intro-footer .carousel-content .desc {
  color: rgba(255, 255, 255, 0.87);
}
.intro-footer .carousel-content .desc a {
  color: #fff;
}
.intro-footer .carousel-content .desc a.more-link {
  vertical-align: middle;
}

.intro-footer .carousel-content .date {
  font-size: .79rem;
  color: rgba(255,255,255,0.56);
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  .intro-footer {
    min-height: 300px;
  }
  .intro-footer .intro-footer-inner {
    padding-left: 40px;
    padding-right: 40px;
  }
  .intro-footer .carousel-content {
    padding-left: 0;
    padding-top: 112px;
  }
  .intro-footer .carousel-content .btn-cta {
    font-size: 12px;
    padding: .25rem .5rem;
    top: 68px;
  }
  .intro-footer .carousel .carousel-item {
    height: 250px;
  }
  .intro-footer .carousel-control-next {
    right: -40px;
  }
  .intro-footer .carousel-control-prev {
    left: -40px;
  }
  .events-container .event-actions {
    position: static;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .events-container .event-title {
    width: 100%;
  }
  .contact-section .social-media-list li {
    margin-bottom: 15px;
  }
  .contact-section .social-media-list .fa,
  .contact-section .social-media-list .fab {
    font-size: 24px;
  }
}

@media (max-width:768px){
  .intro-full-height .intro-body {
    vertical-align: top;
    padding-top: calc(50vh - 200px);
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .intro-footer {
    height: 260px;
  }
  .intro .intro-body {
    padding-bottom: 200px;
  }
  .intro-footer .carousel-content .btn-cta {
    position: static;
    margin-top: .5rem;
  }
  .intro-footer .carousel .carousel-item {
    height: 200px;
  }
  .intro-footer .carousel-control-next {
    right: -80px;
  }
  .intro-footer .carousel-control-prev {
    left: -80px;
  }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .intro-footer {
    height: 160px;
  }
  .intro .intro-body {
    padding-bottom: 100px;
  }
  .intro-footer .carousel-content .btn-cta {
    position: absolute;
  }
  .intro-footer .carousel .carousel-item {
    height: 100px;
  }
  .intro-footer .carousel-control-next {
    right: -125px;
  }
  .intro-footer .carousel-control-prev {
    left: -125px;
  }
}

.intro-footer .instagram-media {
    min-width: 126px!important;
    margin-top: -55px!important;
    min-height: 150px;
    border: none!important;
    border-radius: 0!important;
}

.contact-section {
  background: #101113;
  color: rgba(255, 255, 255, 0.75);
}
.contact-section a {
  color: rgba(255, 255, 255, 0.75);
}
.contact-section a:hover {
  color: var(--color-primary);
}
.contact-section .section-title {
  margin-bottom: 30px;
  color: #fff;
}
.contact-section .section-intro {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 60px;
  font-size: 16px;
}
.contact-section .contact-block {
  max-width: 800px;
  margin-bottom: 90px;
}
.contact-section .contact-block .item {
  margin-bottom: 30px;
}
.contact-section .contact-block .title {
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin-top: 0;
  margin-bottom: 5px;
}
.contact-section .contact-block .icon-holder {
  margin-bottom: 15px;
}
.contact-section .contact-block .fa,
.contact-section .contact-block .fab {
  margin-right: 5px;
  color: var(--color-primary);
  font-size: 24px;
}
.contact-section .channels-list {
  margin-bottom: 45px;
}
.contact-section .channels-list li {
  margin-right: 15px;
  margin-bottom: 15px;
}
.contact-section .channels-list li:last-child {
  margin-right: 0;
}
.contact-section .channels-list a {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
}
.contact-section .channels-list a:hover {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.contact-section .social-media-list {
  margin-bottom: 25px;
}
.contact-section .social-media-list a {
  color: rgba(255, 255, 255, 0.4);
}
.contact-section .social-media-list a:hover {
  color: #fff;
}
.contact-section .social-media-list li {
  margin-right: 15px;
  margin-bottom: 15px;
}
.contact-section .social-media-list li:last-child {
  margin-right: 0;
}
.contact-section .social-media-list .fa,
.contact-section .social-media-list .fab {
  font-size: 36px;
}

.social-block {
  padding-top: 90px;
}
.social-block .instagram-block {
  margin-bottom: 30px;
  max-width: 800px;
}
.social-block .instagram-block .title {
  margin-top: 0;
  margin-bottom: 30px;
}
.social-block .instafeed-wrapper {
  margin-bottom: 30px;
  overflow: hidden;
}
.social-block .instagram-item {
  display: inline-block;
  padding: 0;
  background: #101113;
  -webkit-opacity: 0.85;
  -moz-opacity: 0.85;
  opacity: 0.85;
  position: relative;
}
.social-block .instagram-item:hover {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  z-index: 10;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.owl-carousel.owl-theme .owl-nav {
  margin-top: 0; }
  .owl-carousel.owl-theme .owl-nav .owl-prev,
  .owl-carousel.owl-theme .owl-nav .owl-next {
    opacity: .5;
    font-size: 30px !important;
    line-height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 60px;
    margin: auto 0 !important;
    text-align: center;
    color: white !important;
    border-radius: 0 !important;
    background-color: var(--color-primary) !important;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s; }
    @media (min-width: 768px) {
      .owl-carousel.owl-theme .owl-nav .owl-prev,
      .owl-carousel.owl-theme .owl-nav .owl-next {
        font-size: 50px !important;
        line-height: 90px;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 70px;
        height: 100px; }}
    .owl-carousel.owl-theme .owl-nav .owl-prev:hover,
    .owl-carousel.owl-theme .owl-nav .owl-next:hover {
      opacity: 1; }
  .owl-carousel.owl-theme .owl-nav .owl-prev {
    left: 15px; }
  .owl-carousel.owl-theme .owl-nav .owl-next {
    right: 15px; }
  .owl-carousel.owl-theme .owl-nav .owl-page span {
    background: rgba(255, 255, 255, 0.5); }

.owl-carousel.owl-theme.portfolio-carousel .owl-nav .owl-prev {
  left: 0; }

.owl-carousel.owl-theme.portfolio-carousel .owl-nav .owl-next {
  right: 0; }

.owl-carousel.owl-theme.portfolio-carousel .owl-dots {
  position: relative;
  top: 8px;
}

.portfolio-carousel .table th, .portfolio-carousel .table td {
  border-top: none;
  border-bottom: none;
}

.portfolio-carousel .owl-controls { padding-top: 25px; }

.slidey-slide > .slidey-slide-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.5;
  background-position: 50% 0%;
}

.intro {
  background: none!important; }
  .intro .slidie-slide {
    display: table-header-group; }
  /* .intro .slidie-slide > .slidie-slide-image {
    background-image: url(../img/intro-bg.jpg); } */
.media-section {
  background: none!important; }
  /* .media-section .slidie-slide > .slidie-slide-image {
    background-image: url(../img/media-bg.jpg); } */

.testimonial .lead {
  margin: 15px 0; }
.testimonial .lead .quotation-mark {
  display: inline-block;
  color: rgba(255, 255, 255, 0.33);
  position: relative; }
  .testimonial .lead .quotation-mark.fa-quote-left {
    margin: 0 15px 0 0; top: -10px; }
  .testimonial .lead .quotation-mark.fa-quote-right {
    margin: 0 0 0 15px; bottom: -10px; }
  .testimonial .lead + .quote {
    margin-top: 0; }
  .testimonial .quote i {
    color: rgba(255, 255, 255, 0.33); }

.quote {
  margin: 15px 0;
  font-size: 21px; }
.quote .quotation-mark {
  display: inline-block;
  color: rgba(255, 255, 255, 0.33);
  position: relative; }
  .quote .quotation-mark.fa-quote-left {
    margin: 0 15px 0 0; top: -10px; }
  .quote .quotation-mark.fa-quote-right {
    margin: 0 0 0 15px; bottom: -10px; }

  .quote .small {
    display: block;
    font-weight: 700;
    padding: 1.6rem 0 1.6rem 12rem;
  }
  .quote .small:before {
    content: '— ';
    color: rgba(255, 255, 255, 0.33); }

.copyright-extra {
  color: var(--color-secondary);
}
.copyright-extra a {
  color: var(--color-secondary);
}

.grid.card-columns .card {
  background-color: transparent;
  border: 0;
}

.popup-content {
  background: var(--color-dark);
}

.popup-content .btn-close::before {
  content: '⨉';
  color: var(--color-light);
}